/* eslint-disable */
import React, { useEffect, useRef } from 'react';
import { HiX } from "react-icons/hi";
import Links from "./components/Links";
import Logo from "assets/logo/logo-transp.png";
import LogoLight from "assets/logo/logo-white.png";
import routes from "routes.js";
import { useWindowScroll } from 'react-use';

const Sidebar = ({ open, onClose }) => {
  const { y: scrollY } = useWindowScroll();
  const docHeight = document.documentElement.scrollHeight - window.innerHeight;
  const scrollPercent = (scrollY / docHeight) * 100;
  const sidebarRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
        onClose();
      }
    };

    if (window.innerWidth < 1200) {
      document.addEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [onClose]);

  return (
    <div ref={sidebarRef}
      className={`sm:none duration-175 linear fixed !z-50 flex min-h-full flex-col bg-white pb-10 shadow-2xl shadow-white/5 transition-all dark:!bg-grey-700 dark:text-white md:!z-50 lg:!z-50 xl:!z-0 ${
        open ? "translate-x-0" : "-translate-x-96"
      }`}
    >
      <span
        className="absolute top-4 right-4 block cursor-pointer xl:hidden"
        onClick={onClose}
      >
        <HiX />
      </span>

      <div className={`flex items-center justify-center`}>
        <div className="w-48 me-0 dark:me-2 py-5 dark:block hidden">
          <img src={Logo} alt="logo" className="bg-contain" />
        </div>
        <div className="w-48 me-0 dark:me-2 py-5 dark:hidden block">
          <img src={LogoLight} alt="logo" className="bg-contain" />
        </div>
      </div>
      {/* Nav item */}

      <ul className="mb-auto">
        <Links routes={routes} />
      </ul>

      {/* Nav item end */}
    </div>
  );
};

export default Sidebar;
