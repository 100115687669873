import React, { useEffect, useRef, useState } from "react";
import Dropdown from "components/dropdown";
import { FiAlignJustify, FiSearch } from "react-icons/fi";
import { Link } from "react-router-dom";
import { RiMoonFill, RiSunFill } from "react-icons/ri";
import { IoClose } from "react-icons/io5";
import routes from "routes";
import Matrix from 'components/matrix/Matrix';

const Navbar = (props) => {
  const { onOpenSidenav, brandText, sideBarStatus } = props;
  const [darkMode, setDarkMode] = useState(true);
  const [searchInput, setSearchInput] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [clickCount, setClickCount] = useState(0);
  const [showPopup, setShowPopup] = useState(false);
  const [countdown, setCountdown] = useState(5);
  const [showMatrix, setShowMatrix] = useState(false);
  const searchRef = useRef(null);

  useEffect(() => {
    if (darkMode) {
      document.body.classList.add("dark");
    } else {
      document.body.classList.remove("dark");
    }
  }, [darkMode]);

  const toggleDarkMode = () => {
    setDarkMode((prevMode) => !prevMode);
  };

  const handleSearchChange = (e) => {
    const value = e.target.value;
    setSearchInput(value);

    if (value) {
      const results = routes.filter((route) =>
        route.name.toLowerCase().includes(value.toLowerCase())
      );
      setSearchResults(results);
    } else {
      setSearchResults([]);
    }
  };

  const handleImageClick = () => {
    setClickCount(prevCount => prevCount + 1);
    if (clickCount + 1 === 7) {
      setShowPopup(true);
      setCountdown(5);
    }
  };

  useEffect(() => {
    let timer;
    if (showPopup && countdown > 0) {
      timer = setTimeout(() => {
        setCountdown(countdown - 1);
      }, 1000);
    } else if (countdown === 0) {
      setShowPopup(false);
      setShowMatrix(true);
    }
    return () => clearTimeout(timer);
  }, [showPopup, countdown]);


  return (
    <>
    <nav className="sticky rounded-2xl top-4 z-40 flex flex-row flex-wrap items-center justify-between bg-white/10 p-2 backdrop-blur-xl dark:bg-secondary-200">
      <div className="ml-[6px]">
        <div className="h-6 w-[224px] pt-1">
          <a
            className="text-sm font-normal text-navy-700 hover:underline dark:text-white dark:hover:text-white"
            href=" "
          >
            <span className="mx-1 text-sm text-navy-700 hover:text-navy-700 dark:text-white">
              {" "}
              /{" "}
            </span>
          </a>
          <Link
            className="text-sm font-normal capitalize text-navy-700 hover:underline dark:text-white dark:hover:text-white"
            to="#"
          >
            {brandText}
          </Link>
        </div>
        <p className="shrink text-[33px] capitalize text-navy-700 dark:text-white">
          <Link
            to="#"
            className="font-bold capitalize hover:text-navy-700 dark:hover:text-white"
          >
            {brandText}
          </Link>
        </p>
      </div>

      <div
        className="relative mt-[3px] flex h-[61px] w-[355px] flex-grow items-center justify-around gap-2 rounded-full bg-white px-2 py-2 shadow-xl shadow-shadow-500 dark:!bg-grey-200 dark:shadow-none md:w-[365px] md:flex-grow-0 md:gap-1 xl:w-[365px] xl:gap-2"
        ref={searchRef}
      >
        <div className="flex h-full items-center rounded-full bg-lightPrimary text-navy-700 dark:bg-grey-300 dark:text-white xl:w-[225px]">
          <p className="pl-3 pr-2 text-xl">
            <FiSearch className="h-4 w-4 text-gray-400 dark:text-white" />
          </p>
          <input
            type="text"
            placeholder="Search..."
            value={searchInput}
            onChange={handleSearchChange}
            className="block h-full w-full rounded-full bg-lightPrimary text-sm font-medium text-navy-700 outline-none placeholder:!text-gray-400 dark:bg-grey-300 dark:text-white dark:placeholder:!text-white sm:w-fit"
          />
        </div>
        {searchResults.length > 0 && (
          <div className="absolute top-20 left-0 w-full bg-white dark:bg-grey-400 shadow-lg">
            {searchResults.map((result, index) => (
              <Link
                key={index}
                to={`${result.layout}/${result.path}`}
                className="block px-4 py-2 text-sm text-navy-700 dark:text-white hover:bg-lightPrimary dark:hover:bg-grey-300"
                onClick={() => {
                  setSearchInput("");
                  setSearchResults([]);
                }}
              >
                {result.name}
              </Link>
            ))}
          </div>
        )}
        {!sideBarStatus ? 
          <span
            className="flex cursor-pointer text-xl text-gray-600 dark:text-white xl:hidden"
            onClick={() => onOpenSidenav(true)}
          >
            <FiAlignJustify className="h-5 w-5" />
          </span>
          :
          <span
          className="flex cursor-pointer text-xl text-gray-600 dark:text-white xl:hidden"
          >
            <IoClose className="h-5 w-5" />
          </span>
        }


        <div
          className="cursor-pointer text-gray-600"
          onClick={toggleDarkMode}
        >
          {darkMode ? (
            <RiSunFill className="h-4 w-4 text-gray-600 dark:text-white" />
          ) : (
            <RiMoonFill className="h-4 w-4 text-gray-600 dark:text-white" />
          )}
        </div>
        <img
          className="h-10 w-10 rounded-full"
          src="https://avatar.iran.liara.run/public/boy"
          alt="Elon Musk"
          onClick={handleImageClick}
        />
      </div>
    </nav>
      {showPopup && (
        <div className="fixed inset-0 flex items-center justify-center w-full h-full bg-opacity-50 glassmorphism">
          <div className="bg-red-500 text-white p-6 rounded-lg shadow-lg text-center">
            <p className="text-xl uppercase">Questo sito si autodistruggerà in . . .</p>
            <p className="text-4xl font-bold text-black-600 my-5">{countdown}</p>
          </div>
        </div>
      )}
    {showMatrix && <Matrix />}
    </>
  );
};

export default Navbar;
