import React from 'react';
import { Radar } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  Legend,
} from 'chart.js';

ChartJS.register(
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  Legend
);

const RadarChart = ({ data }) => {
  
  const radarData = {
    labels: ['Totale Eventi Organizzati', 'Totale Tickets', 'Media Incasso Scannerizzato', 'Media Incasso Ipotetico'],
    datasets: [
      {
        label: 'Event Metrics',
        data: [
          data.total_organized_events,
          data.total_tickets,
          (data.avg_scanned_tickets * data.total_earnings) / data.total_tickets,
          data.total_earnings / data.total_tickets,
        ],
        fill: true,
        backgroundColor: 'rgba(255, 99, 132, 0.2)',
        borderColor: 'rgb(255, 99, 132)',
        pointBackgroundColor: 'rgb(255, 99, 132)',
        pointBorderColor: '#fff',
        pointHoverBackgroundColor: '#fff',
        pointHoverBorderColor: 'rgb(255, 99, 132)',
      },
    ],
  };

  const options = {
    scales: {
      r: {
        angleLines: {
          color: '#3B82F6', // Grid lines color
        },
        grid: {
          color: '#3B82F6', // Grid lines color
        },
        pointLabels: {
          color: '#3B82F6', // Label color
        },
        ticks: {
          backdropColor: '#ffffff',
          color: '#3B82F6', // Ticks color
        },
      },
    },
    plugins: {
      legend: {
        labels: {
          color: '#3B82F6', // Legend color
        },
      },
      tooltip: {
        backgroundColor: '#000',
        titleColor: '#3B82F6',
        bodyColor: '#3B82F6',
      },
    },
  };

  return (
    <Radar className='p-0 md:pb-10 rounded-2xl' data={radarData} options={options} />
  );
};

export default RadarChart;
