import React, { useState, useEffect, useRef } from 'react';
import { QRCodeCanvas } from 'qrcode.react';
import img1 from 'assets/emtyCovers/0.png';
import logo from 'assets/logo/logo-transp.png'; // Replace with your logo path
import axios from 'axios';
import { FaExclamationTriangle } from 'react-icons/fa';


const QRCodeGenerator = ({ link, scansione, status, textQRcode }) => {
  const [textInput, setTextInput] = useState(link);
  const qrCodeRef = useRef(null);
  const canvasRef = useRef(null);
  const [showPopup, setShowPopup] = useState(false);
  const [downloaded, setDownloaded] = useState(status);

  useEffect(() => {
    setTextInput(link);
  }, [link]);

  useEffect(() => {
    const qrCanvas = qrCodeRef.current.querySelector('canvas');
    const backgroundCanvas = canvasRef.current;
    const ctx = backgroundCanvas.getContext('2d');

    const img = new Image();
    img.crossOrigin = 'Anonymous'; // Handle cross-origin issues
    img.src = img1;
    img.onload = () => {
      const bgAspectRatio = img.width / img.height;
      const canvasAspectRatio = backgroundCanvas.width / backgroundCanvas.height;

      let bgWidth, bgHeight;
      if (bgAspectRatio > canvasAspectRatio) {
        bgWidth = backgroundCanvas.width;
        bgHeight = bgWidth / bgAspectRatio;
      } else {
        bgHeight = backgroundCanvas.height;
        bgWidth = bgHeight * bgAspectRatio;
      }

      const bgX = (backgroundCanvas.width - bgWidth) / 2;
      const bgY = (backgroundCanvas.height - bgHeight) / 2;
      ctx.drawImage(img, bgX, bgY, bgWidth, bgHeight);

      const qrImage = new Image();
      qrImage.crossOrigin = 'Anonymous'; // Handle cross-origin issues
      qrImage.src = qrCanvas.toDataURL();
      qrImage.onload = () => {
        const qrSize = 250;
        const qrX = (backgroundCanvas.width - qrSize) / 2;
        const qrY = (backgroundCanvas.height - qrSize) / 2;
        ctx.drawImage(qrImage, qrX, qrY, qrSize, qrSize);

        const logoImage = new Image();
        logoImage.crossOrigin = 'Anonymous'; // Handle cross-origin issues
        logoImage.src = logo;
        logoImage.onload = () => {
          const maxLogoWidth = 200;
          const maxLogoHeight = 100;
          const logoAspectRatio = logoImage.width / logoImage.height;

          let logoWidth = maxLogoWidth;
          let logoHeight = logoWidth / logoAspectRatio;

          if (logoHeight > maxLogoHeight) {
            logoHeight = maxLogoHeight;
            logoWidth = logoHeight * logoAspectRatio;
          }

          const logoX = (backgroundCanvas.width - logoWidth) / 2;
          const logoY = qrY - logoHeight - 4;
          ctx.drawImage(logoImage, logoX, logoY, logoWidth, logoHeight);

          const text = textQRcode;
          ctx.font = "30px Arial";
          ctx.fillStyle = "white";
          ctx.textAlign = "center";
          const textX = backgroundCanvas.width / 2;
          const textY = qrY + qrSize + 60;
          ctx.fillText(text, textX, textY);
        };
      };
    };
  }, [link, textQRcode]);

  const handleDownloaded = async () => {
    const header = sessionStorage.getItem('sessionId');
    console.log(link);
    try {
      const response = await axios.post('https://api.bashblitz.com/api/v2/tickets/setTicketDownloaded', {
        ticket: link
      }, {
        headers: {
          Authorization: header,
          'Content-Type': 'application/json',
        }
      });
      if (response.status === 200) {
        setDownloaded(true);
      }
    } catch (error) {
      console.log('Error during API request:', error);
    }
  };

  const handleDownloadClick = () => {
    if (scansione) {
      return;
    }
    if (downloaded) {
      setShowPopup(true);
      return;
    }
    const backgroundCanvas = canvasRef.current;
    const imageUrl = backgroundCanvas.toDataURL('image/png');
    const downloadLink = document.createElement('a');
    downloadLink.href = imageUrl;
    downloadLink.download = `${textQRcode}.png`;
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
    handleDownloaded();
  };

  const handleDownloadAgainClick = () => {
    const backgroundCanvas = canvasRef.current;
    const imageUrl = backgroundCanvas.toDataURL('image/png');
    const downloadLink = document.createElement('a');
    downloadLink.href = imageUrl;
    downloadLink.download = `${textQRcode}.png`;
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
    setShowPopup(false);
  };

  return (
    <div>
      <div ref={qrCodeRef} style={{ display: 'none' }}>
        <QRCodeCanvas
          id={`qrcode-${textQRcode}`}
          value={textInput}
          size={150}
          level={"H"}
          includeMargin={true}
        />
      </div>
      <canvas
        id={`qrcode-canvas-${textQRcode}`}
        ref={canvasRef}
        width={500}
        height={500}
        style={{ display: 'none' }}
      />
      <button
        className={`mt-2 px-4 py-2 ${!downloaded && !scansione ? 'bg-green-600 text-white' : ''} ${downloaded && !scansione ? 'bg-grey-400 text-white' : ''} ${status && !scansione && !downloaded && 'bg-grey-400 text-white'} ${scansione ? 'bg-red-500 text-white' : ''} rounded flex items-center`}
        onClick={handleDownloadClick}
      >
        {!downloaded && !scansione && !status &&'Scarica QR Code'}
        {status && !scansione && !downloaded && 'Già Scaricato!'}
        {downloaded && !scansione &&'Già Scaricato!'}
        {scansione && 'Già Scansionato!'}
      </button>
      {showPopup && (
        <div className="fixed inset-0 flex items-center justify-center w-full h-full bg-opacity-50 glassmorphism">
          <div className="bg-black-300 text-white p-6 rounded-lg shadow-lg text-center w-full md:w-1/2 xl:w-1/4 mx-10">
            <div className='flex flex-col justify-center items-center'>
              <FaExclamationTriangle className="text-4xl text-yellow-500 mb-4" />
              <p className="text-xl uppercase">Attenzione hai già scaricato questo Ticket sei sicuro di volere scaricare nuovamente il file?</p>
            </div>
            <div className='mt-5'>
              <button className='uppercase w-28 py-2 rounded-xl mx-2 bg-red-500' onClick={() => setShowPopup(false)}>no</button>
              <button className='uppercase w-28 py-2 rounded-xl mx-2 bg-blue-500' onClick={handleDownloadAgainClick}>si</button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default QRCodeGenerator;
