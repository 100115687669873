import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import InputField from 'components/fields/InputField';
import Checkbox from 'components/checkbox';
import Logo from 'assets/logo/logo-transp.png';
import logoWhite from 'assets/logo/logo-white.png';
import Cookies from 'js-cookie';
import sjcl from 'sjcl';
import axios from 'axios';

export default function SignIn() {
  const [utente, setUtente] = useState('');
  const [password, setPassword] = useState('');
  const [isChecked, setIsChecked] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const navigate = useNavigate();
  const secretKey = 'werk-sfgv-veqg-ergf';
  const savedName = Cookies.get('name');
  const savedPassword = Cookies.get('password');

  useEffect(() => {
    if (savedName && savedPassword) {
      try {
        function decryptData(encryptedData, key) {
          const decryptedData = sjcl.decrypt(key, encryptedData);
          return decryptedData;
        }

        // Decrypt the values
        const decryptedName = decryptData(savedName, secretKey);
        const decryptedPassword = decryptData(savedPassword, secretKey);

        console.log('Decrypted Name:', decryptedName);
        console.log('Decrypted Password:', decryptedPassword);

        setUtente(decryptedName);
        setPassword(decryptedPassword);
        setIsChecked(true);
      } catch (error) {
        console.error('Decryption error:', error);
      }
    }
  }, [savedName, savedPassword, secretKey]);

  const handleUtenteChange = (e) => {
    const value = e.target.value;
    setUtente(value);
  };

  const handlePasswordChange = (e) => {
    const value = e.target.value;
    setPassword(value);
  };

  const handleCheckboxChange = (checked) => {
    setIsChecked(checked);
  };

  const handleSubmit = async () => {
    if (isChecked && !savedName && !savedPassword) {
      // Encrypt the name and password
      const encryptedName = sjcl.encrypt(secretKey, utente);
      const encryptedPassword = sjcl.encrypt(secretKey, password);

      // Save the encrypted values in cookies
      Cookies.set('name', encryptedName, { expires: 360 });
      Cookies.set('password', encryptedPassword, { expires: 360 });
    }

    try {
      const response = await axios.post('https://api.bashblitz.com/api/v2/auth/auth', {
        email: utente,
        password: password,
      });

      if (response.status === 200 && response.data.session_token) {
        sessionStorage.setItem('sessionId', response.data.session_token);
        navigate('/admin');
      } else {
        setErrorMessage('Incorrect username or password');
      }
    } catch (error) {
      console.log('Error during API request:', error);
      setErrorMessage('Incorrect username or password');
    }
  };

  return (
    <div className="md:mt-16 mb-16 flex h-full w-full items-center justify-center px-2 md:mx-0 md:px-0 lg:mb-10 lg:items-center lg:justify-start">
      {/* Sign in section */}
      <div className="mt-[10vh] w-full max-w-full flex-col items-center justify-center md:pl-4 lg:pl-0 xl:max-w-[420px]">
        <img src={Logo} alt="Logo" className="p-5 dark:block hidden" />
        <img src={logoWhite} alt="Logo" className="p-5 dark:hidden block" />

        {/* Codice Cliente */}
        <InputField
          variant="auth"
          extra="mb-3"
          label="Email"
          id="nomeUtente"
          type="text"
          value={utente}
          onChange={handleUtenteChange}
        />

        {/* Password */}
        <InputField
          variant="auth"
          extra="mb-3"
          label="Password"
          id="password"
          type="password"
          value={password}
          onChange={handlePasswordChange}
        />

        {/* Checkbox */}
        <div className="mb-4 mt-5 flex items-center justify-between px-2">
          <div className="flex items-center">
            <Checkbox color="blue" checked={isChecked} onChange={(e) => handleCheckboxChange(e.target.checked)} />
            <p className="ml-2 text-sm font-medium text-navy-700 dark:text-white">
              Ricordami
            </p>
          </div>
        </div>

        <button onClick={handleSubmit} className="linear mt-2 w-full rounded-xl bg-blue-500 py-[12px] text-base font-medium text-white transition duration-200 opacity-90 hover:opacity-95 active:opacity-100 dark:text-white">
          Accedi
        </button>

        {errorMessage && (
          <div className="mt-4 p-2 text-red-500 bg-red-100 rounded">
            {errorMessage}
          </div>
        )}
      </div>
    </div>
  );
}
