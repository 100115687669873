// NewTicketForm.jsx
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { FaPlus, FaMinus } from 'react-icons/fa';
import { IoCloseSharp } from "react-icons/io5";
import loadingCreateQR from 'assets/lottie/loadingQR.json';
import successQR from 'assets/lottie/success.json';

import Lottie from 'react-lottie';

const LottieComponent = () => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: loadingCreateQR,
  };

  return <Lottie options={defaultOptions}  />;
};

const LottieSuccess = () => {
    const defaultOptions = {
      loop: false,
      autoplay: true,
      animationData: successQR,
    };
  
    return <Lottie options={defaultOptions}  />;
  };


const NewTicketForm = ({ eventId, onClose, onSuccess }) => {
  const [step, setStep] = useState(1);
  const [priceId, setPriceId] = useState(0);
  const [quantity, setQuantity] = useState(0);
  const [errorMessage, setErrorMessage] = useState('');
  const [prices, setPrices] = useState([]);
  const [newPriceValue, setNewPriceValue] = useState(0);
  const [newPriceDescription, setNewPriceDescription] = useState('');
  const [loading, setLoading] = useState(false);
  const [successQR, setSuccessQR] = useState(false);


  useEffect(() => {
    const fetchPrices = async () => {
      const header = sessionStorage.getItem('sessionId');
      try {
        const response = await axios.get('https://api.bashblitz.com/api/v2/events/getEventPrices', {
          headers: {
            Authorization: header,
            eventId: eventId,
            'Content-Type': 'application/json',
          },
        });
        if (response.status === 200) {
          setPrices(response.data.prices);
          setPriceId(response.data.prices[0].price_id)
        }
      } catch (error) {
        console.error('Error during API request:', error);
      }
    };
    fetchPrices();
  }, []);


const getEventPrices = async () => {
    const header = sessionStorage.getItem('sessionId');
    try {
    const response = await axios.get('https://api.bashblitz.com/api/v2/events/getEventPrices', {
        headers: {
        Authorization: header,
        eventId: eventId,
        'Content-Type': 'application/json',
        },
    });
    if (response.status === 200) {
        setPrices(response.data.prices);
    }
    } catch (error) {
    console.error('Error during API request:', error);
    }
};


const handleSubmit = async (priceId) => {
  const header = sessionStorage.getItem('sessionId');

  if (quantity <= 0) {
    setErrorMessage('Devi creare almeno un QR Code.');
    setLoading(false);
    return;
  }

  try {
    const response = await axios.post('https://api.bashblitz.com/api/v2/tickets/generateTicket', {
      event_id: eventId,
      price_id: priceId,
      quantity: quantity,
    }, {
      headers: {
        Authorization: header,
        'Content-Type': 'application/json',
      },
    });

    if (response.status === 200) {
      setSuccessQR(true);
      setLoading(false);
      setTimeout(() => {
        setSuccessQR(false);
        onSuccess();
        onClose();
      }, 3000);
    } else {
      setLoading(false);
      setSuccessQR(false);
      setErrorMessage('Ops!.. si è verificato un errore, Riprova!');
    }
  } catch (error) {
    setSuccessQR(false);
    setLoading(false);
    console.error('Error during API request:', error);
    setErrorMessage('Ops!.. si è verificato un errore, Riprova!');
  }
};

const showAnimation = () => {
  setLoading(true);
  setTimeout(() => {
    handleSubmit(priceId);
  }, 3000);
};

  const addNewPriceType = async (e) => {
    e.preventDefault();
    const header = sessionStorage.getItem('sessionId');
    if(newPriceDescription !== ''){
        try {
        const response = await axios.post('https://api.bashblitz.com/api/v2/events/addPriceEvent', {
            eventId: eventId,
            price: newPriceValue,
            description: newPriceDescription
        }, {
            headers: {
            Authorization: header,
            'Content-Type': 'application/json',
            },
        });

            if (response.status === 201) {
                setStep(1);
                getEventPrices();
            } else {
                setErrorMessage('Ops!.. si è verificato un errore, Riprova!');
            }
        } catch (error) {
            console.error('Error during API request:', error);
            setErrorMessage('Ops!.. si è verificato un errore, Riprova!');
        }
    }else{
        setErrorMessage('Devi compilare prima tutti i campi.');
    }

  }

  const incrementQuantity = () => setQuantity(Number(quantity) + 1);
  const decrementQuantity = () => setQuantity(Number(quantity) > 0 ? Number(quantity) - 1 : 0);

  const renderStep1 = () => (
    <>
      <h2 className="text-2xl mb-6">Seleziona un prezzo</h2>
      <div className="mb-4">
        <label className="block text-sm font-medium text-gray-700">Ticket type</label>
        <select
          value={priceId}
          onChange={(e) => setPriceId(Number(e.target.value))}
          className="mt-1 block w-full text-black rounded-md border bg-transparent p-2 uppercase"
        >
          {prices.map((price) => (
            <option className='bg-grey-500' key={price.id} value={price.price_id}>{price.description} - &nbsp;{price.price} €</option>
          ))}
        </select>
        <button className='flex items-center text-blue-500 font-semibold mt-3' onClick={() => setStep(3)}>          
            <FaPlus className='me-1'/>Aggiungi un nuovo prezzo
        </button>
      </div>
      <div className="flex justify-center pt-5">
        <button type="button" onClick={onClose} className="px-10 py-2 bg-gray-300 text-white bg-red-700 rounded mr-4">Chiudi</button>
        <button type="button" onClick={() => {setStep(2); setErrorMessage('');}} className="px-10 py-2 bg-blue-600 text-white rounded">Avanti</button>
      </div>
    </>
  );

  const renderStep2 = () => (
    <>
    {loading && 
        <>
            <h2 className="text-2xl my-3">Stiamo generando i tuoi Tickets...</h2>
            <LottieComponent/>
        </> 
    }
    {successQR && 
        <>
             <h2 className="text-2xl my-3">I tuoi Tickets sono stati creati!</h2>
             <LottieSuccess/>
         </> 
    }
    {!loading && !successQR &&
        <>
        <h2 className="text-2xl mb-6">Quanti QR CODES vuoi generare?</h2>
        <div className="mb-4">
            <div className="flex items-center">
            <button type="button" onClick={decrementQuantity} className="px-2 py-1 bg-gray-300 rounded-l">
                <FaMinus className='text-red-500'/>
            </button>
            <input
                type="number"
                value={quantity}
                onChange={(e) => setQuantity((e.target.value).replace(/^0+(?!$)/, ''))}
                className="mt-1 block w-full text-center border rounded bg-transparent p-2"
                required
            />
            <button type="button" onClick={incrementQuantity} className="px-2 py-1 bg-gray-300 rounded-r">
                <FaPlus className='text-blue-500'/>
            </button>
            </div>
        </div>
        {errorMessage && <p className="text-red-500 text-center">{errorMessage}</p>}
        <div className="flex justify-center pt-5">
            <button type="button" onClick={() => {setStep(1); setErrorMessage('');}} className="px-10 py-2 bg-gray-300 text-white bg-red-700 rounded mr-4">Indietro</button>
            <button type="submit" className="px-10 py-2 bg-blue-600 text-white rounded" onClick={showAnimation}>Crea Tickets</button>
        </div>
        </>
    }
    </>
  );

  const renderStep3 = () => (
    <>
      <h2 className="text-2xl mb-6 text-center">Aggiungi un nuovo prezzo</h2>
      <div className="mb-4 relative flex justify-center items-center">
        <input
          type="number"
          value={newPriceValue}
          onChange={(e) => setNewPriceValue((e.target.value).replace(/^0+(?!$)/, ''))}
          className="mt-1 block w-full text-black rounded-md border bg-transparent p-2 pr-8"
          required
        /> 
          <span className="absolute p-2 inset-y-0 right-0 pr-6 pb-1 flex items-center text-gray-700">€</span>
      </div>
      <div className="mb-4">
        <label className="block text-sm font-medium text-gray-700">Etichetta:</label>
        <input
          type="text"
          value={newPriceDescription}
          onChange={(e) => setNewPriceDescription(e.target.value)}
          className="mt-1 block w-full text-black rounded-md border bg-transparent p-2"
          required
        />
      </div>
      {errorMessage && <p className="text-red-500 text-center">{errorMessage}</p>}
      <div className="flex justify-center pt-5">
        <button type="button" onClick={() => {setStep(1); setErrorMessage('');}} className="px-10 py-2 bg-gray-300 text-white bg-red-700 rounded mr-4">Indietro</button>
        <button type="button" onClick={addNewPriceType} className="px-10 py-2 bg-blue-600 text-white rounded">Avanti</button>
      </div>
    </>
  );

  return (
    <div className="fixed inset-0 flex items-center justify-center glassmorphism">
      <div className="bg-grey-500 text-white rounded-lg shadow-lg w-96 p-6">
        <div className='flex justify-end'>
            <button onClick={onClose} className="text-gray-700 mb-4">
            <div className='flex items-center'>
                <IoCloseSharp className='mr-2' />Chiudi
            </div>
            </button>
        </div>
        <form>
          {step === 1 && renderStep1()}
          {step === 2 && renderStep2()}
          {step === 3 && renderStep3()}
        </form>
      </div>
    </div>
  );
};

export default NewTicketForm;
