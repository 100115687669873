import React from "react";

// Admin Imports
import Home from "views/admin/home";
import Eventi from "views/admin/eventi/index";
import Support from "views/admin/support/index";
import ScanApp from "views/admin/scanApp/index";
import CreateEvent from "views/admin/createEvent/index";

// Auth Imports
import SignIn from "views/auth/SignIn";
import { FaRegChartBar, FaQrcode  } from "react-icons/fa";
import { BiSupport } from "react-icons/bi";
import { GiPartyPopper } from "react-icons/gi";
import { GoPlusCircle } from "react-icons/go";



// Icon Imports
import {
  MdOutlineLogout, 
} from "react-icons/md";

const routes = [
  {
    name: "Crea Evento",
    layout: "/admin",
    path: "create-events",
    icon: <GoPlusCircle  className="h-6 w-6" />,
    component: <CreateEvent />,
  },
  {
    name: "Statistiche",
    layout: "/admin",
    path: "statistiche",
    icon: <FaRegChartBar  className="h-6 w-6" />,
    component: <Home />,
  },
  {
    name: "Eventi",
    layout: "/admin",
    path: "eventi",
    icon: <GiPartyPopper className="h-6 w-6" />,
    component: <Eventi />,
    secondary: true,
  },
  {
    name: "App scansioni",
    layout: "/admin",
    path: "app-scansioni",
    icon: <FaQrcode className="h-6 w-6" />,
    component: <ScanApp />,
  },
  {
    name: "Assistenza",
    layout: "/admin",
    path: "assistenza",
    icon: <BiSupport className="h-6 w-6" />,
    component: <Support />,
  },
  {
    name: "Esci",
    layout: "/auth",
    path: "sign-in",
    icon: <MdOutlineLogout className="h-6 w-6" />,
    component: <SignIn />,
  },
];
export default routes;
