import React from 'react';
import { FaDownload, FaQrcode, FaClipboardCheck, FaCheckCircle, FaCopy } from 'react-icons/fa';
import appleStore from "assets/logo/apple.png";
import playStore from "assets/logo/google.png";
import appCodiceScreen from "assets/logo/scan-app.png";
import appScanScreen from "assets/logo/scan.png";



const steps = [
  {
    icon: <FaDownload size={30} className="text-blue-500" />,
    title: "Scarica l'app",
    description: "Scarica l'app dallo store.",
    extraCode:`
    <div class="flex flex-row justify-start items-start  my-4 gap-4 sm:ml-12">
      <img src=${appleStore} class="w-28 h-auto" alt="" />   
      <img src=${playStore} class="w-28 h-auto" alt="" />   
    </div>` ,
  },
  {
    icon: <FaCopy size={30} className="text-black-500 dark:text-white" />,
    title: "Copia il codice evento",
    description: "Copia il codice evento dalla pagina Dettagli evento.",
    extraCode:`
    <div class="text-blue-500 ml-12">
      <a href="/admin/eventi">Link alla pagina.</a>
    </div>`,
  },
  {
    icon: <FaClipboardCheck size={30} className="text-blue-500" />,
    title: "Inserisci il codice evento",
    description: "Inserisci il codice evento di 6 cifre che hai ricevuto dopo la registrazione.",
    extraCode:`
    <div class="flex flex-row justify-start items-start my-0 gap-4 mt-10 mb-5">
      <img src=${appCodiceScreen} class="w-56 h-auto" alt="" />   
    </div>` ,
  },
  {
    icon: <FaQrcode size={30} className="text-black-500 dark:text-white" />,
    title: "Inizia a scansionare",
    description: "Usa l'app per scansionare i codici QR visualizzati all'evento.",
    extraCode:`
    <div class="flex flex-row justify-start items-start my-0 gap-4 mt-10 mb-5">
      <img src=${appScanScreen} class="w-56 h-auto" alt="" />   
    </div>` ,  },
  {
    icon: <FaCheckCircle size={30} className="text-green-700" />,
    title: "Verifica i dettagli del biglietto",
    description: "Controlla i dettagli del biglietto per verificarne la validità e lo stato della scansione.",
    extraCode:`<div></div>` ,
  }
];

const StepCard = ({ icon, title, description, extraCode }) => (
  <div className='p-4 rounded-[20px] bg-white shadow-md dark:bg-grey-800 dark:text-white'>
    <div className="flex items-center justify-start gap-4">
      <div className="flex-shrink-0">
        {icon}
      </div>
      <div>
        <h3 className="text-lg font-semibold">{title}</h3>
        <p className="text-gray-600 dark:text-gray-300">{description}</p>
      </div>
    </div>
    <div dangerouslySetInnerHTML={{__html:extraCode}}></div>
  </div>
);

const ScanApp = () => {
  return (
    <div className="flex w-full flex-col gap-5 py-10 bg-gray-100 dark:bg-gray-900 min-h-screen">
      <h1 className="text-2xl font-bold my-6 text-start text-black-900 dark:text-white">Come utilizzare l'app per scansionare i codici QR</h1>
      <div className="flex flex-col gap-6">
        {steps.map((step, index) => (
          <StepCard key={index} {...step} />
        ))}
      </div>
    </div>
  );
};

export default ScanApp;
