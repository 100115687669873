import React, { useEffect, useState } from "react";
import { RiMoonFill, RiSunFill } from "react-icons/ri";

export default function FixedPlugin(props) {
  const { ...rest } = props;
  const [darkMode, setDarkMode] = useState(true);

  useEffect(() => {
    if (darkMode) {
      document.body.classList.add("dark");
    } else {
      document.body.classList.remove("dark");
    }
  }, [darkMode]);

  const toggleDarkMode = () => {
    setDarkMode((prevMode) => !prevMode);
  };

  return (
    <button
      className="border-px fixed bottom-[30px] left-[35px] !z-[99] flex h-[60px] w-[60px] items-center justify-center rounded-full text-white dark:text-black-900 bg-black-900 dark:bg-white p-0"
      onClick={toggleDarkMode}
      {...rest}
    >
      <div className="cursor-pointer text-gray-600">
        {darkMode ? (
          <RiSunFill className="h-4 w-4" />
        ) : (
          <RiMoonFill className="h-4 w-4" />
        )}
      </div>
    </button>
  );
}
