import React from "react";
import Card from "components/card";
import {
  lineChartDataTotalSpent,
  lineChartOptionsTotalSpent,
} from "variables/charts";
import LineChart from "components/charts/LineChart";
import { FaChartPie } from "react-icons/fa";
import RadarChart from "./RadarChart";

const TotalSpent = ({data}) => {
  return (
    <div className="flex items-center justify-center bg-gray-100 dark:bg-gray-900">
      <Card extra="!p-[20px] text-center rounded-[20px] w-full h-full">
        <div className="flex justify-between mb-4">
          <button className="linear mt-1 flex items-center justify-center gap-2 bg-lightPrimary p-2 text-gray-600 rounded-lg transition duration-200 hover:cursor-pointer hover:bg-gray-100 active:bg-gray-200 dark:bg-grey-900 dark:hover:opacity-90 dark:active:opacity-80">
            <FaChartPie className="h-6 w-6 text-blue-500"/>
            <span className="text-sm font-medium text-gray-600">I tuoi Eventi</span>
          </button> 
        </div>

        <div className="flex h-full w-full flex-col lg:flex-row justify-between">
          <div className="flex flex-col w-full lg:w-1/5 justify-start items-start">
            <div>
              <p className="mt-[20px] text-3xl font-bold text-navy-700 dark:text-white text-start">
                {data.total_tickets}
              </p>
              <div className="flex flex-col items-start text-start">
                <p className="mt-2 text-sm text-gray-600">Totale Partecipanti</p>
              </div>
            </div>
            <div>
              <p className="mt-[20px] text-3xl font-bold text-navy-700 dark:text-white text-start">
                {data.total_organized_events}
              </p>
              <div className="flex flex-col items-start text-start">
                <p className="mt-2 text-sm text-gray-600">Totale Eventi</p>
              </div>
            </div>
            <div>
              <p className="mt-[20px] text-3xl font-bold text-navy-700 dark:text-white text-start">
                {data.total_earnings} €
              </p>
              <div className="flex flex-col items-start text-start">
                <p className="mt-2 text-sm text-gray-600">Totale Incasso</p>
              </div>
            </div>
          </div>
          <div className="flex flex-col w-full lg:w-4/5 justify-start items-start md:h-[60vh]">
            <RadarChart data={data} />
          </div>
        </div>

      </Card>
    </div>
  );
};

export default TotalSpent;
