import React, { useEffect, useState, useCallback } from 'react';
import axios from 'axios';
import { GoPlus } from "react-icons/go";
import { MdOutlineFileDownload } from "react-icons/md";
import JSZip from 'jszip';
import { saveAs } from 'file-saver';
import NewTicketForm from '../components/NewTicketForm';
import QRCode from './QRCodeGenerator';
import Checkbox from "components/checkbox";
import { IoIosRefresh } from "react-icons/io";
import { FaExclamationTriangle } from 'react-icons/fa';


const QRCodeTable = ({ eventName, idEvento }) => {
  const [qrCodes, setQrCodes] = useState([]);
  const [showForm, setShowForm] = useState(false);
  const [selectedCodes, setSelectedCodes] = useState([]);
  const [qrCodeLink, setQrCodeLink] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [sortConfig, setSortConfig] = useState({ key: null, direction: 'asc' });
  const [showPopup, setShowPopup] = useState(false);
  const [showPopupText, setShowPopupText] = useState('');
  const [showButton, setShowButton] = useState(true);



  const fetchTickets = useCallback(async () => {
    const header = sessionStorage.getItem('sessionId');
    try {
      const response = await axios.get('https://api.bashblitz.com/api/v2/tickets/getTicketsByEvent', {
        headers: {
          Authorization: header,
          eventId: idEvento,
          'Content-Type': 'application/json',
        }
      });
      if (response.status === 200) {
        const tickets = response.data.tickets;
        const filteredTickets = [
          ...tickets.filter(ticket => !ticket.scanned && !ticket.isDownloaded),
          ...tickets.filter(ticket => !ticket.scanned && ticket.isDownloaded),
          ...tickets.filter(ticket => ticket.scanned)
        ];
        setQrCodes(filteredTickets);      
      }
    } catch (error) {
      console.log('Error during API request:', error);
    }
  }, [idEvento]);

  useEffect(() => {
    fetchTickets();
  }, [fetchTickets]);

  const handleFormClose = () => {
    setShowForm(false);
  };

  const handleFormSuccess = useCallback(() => {
    fetchTickets();
    setShowButton(false);
    setTimeout(() => {
      setShowButton(true);
    }, 500);
  }, [fetchTickets]);

  const handleSelectAll = () => {
    setSelectAll(!selectAll);
    if (!selectAll) {
      setSelectedCodes(qrCodes.map(qrCode => qrCode.ticket_code));
      setQrCodeLink(qrCodes.map(qrCode => qrCode.qr_code));
    } else {
      setSelectedCodes([]);
      setQrCodeLink([]);
    }
  };

  const handleCheckboxChange = (ticketCode, qrCode) => {
    if (selectedCodes.includes(ticketCode)) {
      setSelectedCodes(selectedCodes.filter(code => code !== ticketCode));
      setQrCodeLink(qrCodeLink.filter(code => code !== qrCode));
    } else {
      setSelectedCodes([...selectedCodes, ticketCode]);
      setQrCodeLink([...qrCodeLink, qrCode]);
    }
  };

  const handleRefreshData = () => {
    fetchTickets();
    setShowButton(false);
    setTimeout(() => {
      setShowButton(true);
    }, 500);
  }

  const handleSetDownloaded = async () => {
    const header = sessionStorage.getItem('sessionId');
    try {
      const response = await axios.post('https://api.bashblitz.com/api/v2/tickets/setMultipleDownloaded', {
        tickets: qrCodeLink
      }, {
        headers: {
          Authorization: header,
          'Content-Type': 'application/json',
        }
      });
      if (response.status === 200) {
        handleRefreshData();
        setSelectedCodes([])
      } else {
        console.log(response.status);
      }
    } catch (error) {
      console.log('Error during API request:', error);
    }
  };

  const checkScannedOrDownloaded = () => {
    const alreadyScanned = selectedCodes.some(code => {
      const qrCode = qrCodes.find(qr => qr.ticket_code === code);
      return qrCode ? qrCode.scanned : false;
    });

    const alreadyDownloaded = selectedCodes.some(code => {
      const qrCode = qrCodes.find(qr => qr.ticket_code === code);
      return qrCode ? qrCode.isDownloaded : false;
    });

    return { alreadyScanned, alreadyDownloaded };
  };

  const handleDownloadSelected = async () => {
    const { alreadyScanned, alreadyDownloaded } = checkScannedOrDownloaded();
    if (alreadyScanned && alreadyDownloaded){
      setShowPopupText('Ci sono codici scansionati e scaricati tra i codici selezionati. Vuoi procedere lo stesso?');
      setShowPopup(true);
      return;
    }
    if (alreadyScanned) {
      setShowPopupText('Ci sono codici scansionati tra i codici selezionati. Vuoi procedere lo stesso?');
      setShowPopup(true);
      return;
    }
    if (alreadyDownloaded) {
      setShowPopupText('Ci sono codici scaricati tra i codici selezionati. Vuoi procedere lo stesso?');
      setShowPopup(true);
      return;
    }
    const zip = new JSZip();
    const imgFolder = zip.folder(eventName);

    for (const ticketCode of selectedCodes) {
      const canvas = document.getElementById(`qrcode-canvas-${ticketCode}`);
      if (canvas) {
        const imageUrl = canvas.toDataURL('image/png');
        const blob = await (await fetch(imageUrl)).blob();
        const fileName = `${ticketCode}.png`;
        imgFolder.file(fileName, blob);
      }
    }

    zip.generateAsync({ type: 'blob' }).then((content) => {
      saveAs(content, `bashblitz.zip`);
      handleSetDownloaded();
    });
  };

  const handleDangerousDownloadSelected = async () => {
    const zip = new JSZip();
    const imgFolder = zip.folder(eventName);

    for (const ticketCode of selectedCodes) {
      const canvas = document.getElementById(`qrcode-canvas-${ticketCode}`);
      if (canvas) {
        const imageUrl = canvas.toDataURL('image/png');
        const blob = await (await fetch(imageUrl)).blob();
        const fileName = `${ticketCode}.png`;
        imgFolder.file(fileName, blob);
      }
    }

    zip.generateAsync({ type: 'blob' }).then((content) => {
      saveAs(content, `bashblitz.zip`);
      handleSetDownloaded();
    });
    setShowPopup(false);
  };

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const handleSort = (key) => {
    let direction = 'asc';
    if (sortConfig.key === key && sortConfig.direction === 'asc') {
      direction = 'desc';
    }
    setSortConfig({ key, direction });
  };

  const filteredQrCodes = qrCodes.filter((qrCode) =>
    qrCode.price_description.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const sortedQrCodes = React.useMemo(() => {
    if (sortConfig.key) {
      return [...filteredQrCodes].sort((a, b) => {
        if (a[sortConfig.key] < b[sortConfig.key]) {
          return sortConfig.direction === 'asc' ? -1 : 1;
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
          return sortConfig.direction === 'asc' ? 1 : -1;
        }
        return 0;
      });
    }
    return filteredQrCodes;
  }, [filteredQrCodes, sortConfig]);


  return (
    <div className="bg-white dark:bg-grey-800 dark:text-white lg:p-10 rounded-xl">
      <h2 className="text-xl text-center font-semibold mb-5">QR Codes {eventName}</h2>
      <div className="flex flex-col md:flex-row justify-between md:justify-center items-center mb-4">
        <div className='flex flex-col justify-center sm:flex-row gap-5'>
          {qrCodes.length > 0 && selectedCodes.length > 0 &&
            <div className='hidden md:block'>
              <button
                onClick={handleDownloadSelected}
                className="px-4 py-2 bg-green-600 text-white rounded flex items-center"
                disabled={selectedCodes.length === 0}
              >
                <MdOutlineFileDownload className='me-3' />
                Scarica Selezionati
              </button>
            </div>
          }
          <button className="px-4 py-2 bg-blue-600 text-white rounded flex items-center sm:mb-5" onClick={() => setShowForm(true)}>
            <GoPlus className='me-3' />Genera QR Codes
          </button>
          <button className="px-4 py-2 bg-black-200 text-white rounded flex items-center mb-5" onClick={handleRefreshData}>
            <IoIosRefresh className='me-3' />
            Ricarica Pagina
          </button>
        </div>
      </div>
      <div className="mb-4">
        <input
          type="text"
          placeholder="Search Ticket Name. . ."
          value={searchQuery}
          onChange={handleSearchChange}
          className="px-4 py-2 rounded-full w-full text-black-900 border dark:border-none"
        />
      </div>
      {qrCodes.length === 0 ? (
        <p>Non ci sono QR CODES disponibili!</p>
      ) : (
        <div className="overflow-x-auto">
          <table className="min-w-full text-white">
            <thead>
              <tr className="bg-gray-700">
                <th className="hidden md:table-cell py-2 px-4 border border-black-800 text-black-900 dark:text-black-50 dark:border-grey-400 text-center">
                  <Checkbox checked={selectAll} onChange={handleSelectAll} />
                </th>
                <th className="py-2 px-4 border border-black-800 text-black-900 dark:text-black-50 dark:border-grey-400 text-center cursor-pointer" onClick={() => handleSort('price_description')}>Ticket Name</th>
                <th className="py-2 px-4 border border-black-800 text-black-900 dark:text-black-50 dark:border-grey-400 text-center cursor-pointer" onClick={() => handleSort('price')}>Price</th>
                <th className="hidden md:table-cell py-2 px-4 border border-black-800 text-black-900 dark:text-black-50 dark:border-grey-400 text-center cursor-pointer" onClick={() => handleSort('ticket_code')}>Number</th>
                <th className="py-2 px-4 border border-black-800 text-black-900 dark:text-black-50 dark:border-grey-400 text-center">Download</th>
              </tr>
            </thead>
            <tbody>
              {sortedQrCodes.map((qrCode, index) => (
                <tr key={index} className="bg-gray-600">
                  <td className="hidden md:table-cell py-2 px-4 border border-black-800 text-black-900 dark:text-black-50 dark:border-grey-400 text-center">
                    <Checkbox checked={selectedCodes.includes(qrCode.ticket_code)} onChange={() => handleCheckboxChange(qrCode.ticket_code, qrCode.qr_code)} />
                  </td>
                  <td className="py-2 px-4 border border-black-800 text-black-900 dark:text-black-50 dark:border-grey-400 text-center">{qrCode.price_description}</td>
                  <td className="py-2 px-4 border border-black-800 text-black-900 dark:text-black-50 dark:border-grey-400 text-center">
                    {qrCode.price} €
                  </td>
                  <td className="hidden md:table-cell py-2 px-4 border border-black-800 text-black-900 dark:text-black-50 dark:border-grey-400 text-center">
                    {qrCode.ticket_code}
                  </td>
                  <td className="py-2 px-4 border border-black-800 text-black-900 dark:text-black-50 dark:border-grey-400 text-center flex justify-center">
                    {showButton ? 
                      <QRCode
                        scansione={qrCode.scanned}
                        status={qrCode.isDownloaded}
                        link={`${qrCode.qr_code}`}
                        textQRcode={qrCode.ticket_code}
                      />
                    :
                    <p className='uppercase'>Loading. . .</p>
                    }
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
      {showForm && <NewTicketForm eventId={idEvento} onClose={handleFormClose} onSuccess={handleFormSuccess} refreshdata={handleRefreshData} />}
      {showPopup && (
        <div className="fixed inset-0 flex items-center justify-center w-full h-full bg-opacity-50 glassmorphism">
          <div className="bg-black-300 text-white p-6 rounded-lg shadow-lg text-center w-full md:w-1/2 xl:w-1/4 mx-10">
            <div className='flex flex-col justify-center items-center'>
              <FaExclamationTriangle className="text-4xl text-yellow-500 mb-4" />
              <p className="text-xl uppercase">{showPopupText}</p>
            </div>
            <div className='mt-5'>
              <button className='uppercase w-28 py-2 rounded-xl mx-2 bg-red-500' onClick={() => setShowPopup(false)}>no</button>
              <button className='uppercase w-28 py-2 rounded-xl mx-2 bg-blue-500' onClick={handleDangerousDownloadSelected}>si</button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default QRCodeTable;
