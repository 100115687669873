import React, { useState, useEffect } from 'react';
import confetti from 'canvas-confetti';
import axios from 'axios';

const CreateEvent = () => {
  const [eventData, setEventData] = useState({
    name: '',
    date: '',
    place: '',
    city: '',
    address: '',
    price: 0,
    price_description: 'standard',
  });
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [confettiCanvas, setConfettiCanvas] = useState(null);

  useEffect(() => {
    const canvas = document.createElement('canvas');
    canvas.className = 'confetti-canvas';
    canvas.style.position = 'fixed';
    canvas.style.top = '0';
    canvas.style.left = '0';
    canvas.style.width = '100%';
    canvas.style.height = '100%';
    canvas.style.pointerEvents = 'none';
    canvas.style.zIndex = '9999';
    document.body.appendChild(canvas);
    setConfettiCanvas(canvas);
    return () => {
      document.body.removeChild(canvas);
    };
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setEventData({ ...eventData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const isFormValid = Object.values(eventData).every((value) => value !== '');
    const header = sessionStorage.getItem('sessionId');

    if (isFormValid) {
      try {
        const response = await axios.post('https://api.bashblitz.com/api/v2/events/createEvent', 
          eventData,
          {
            headers: {
              Authorization: header,
              'Content-Type': 'application/json' 
            }
          }
        );
        if (response.status === 201) {
          setShowSuccessModal(true);
          launchConfetti();
          return;
        }
      } catch (error) {
        console.log('Error during API request:', error);
        setShowErrorModal(true);
      }
    } else {
      setShowErrorModal(true);
    }
  };

  const handleSuccess = () => {
    setShowSuccessModal(false);
    setEventData({
      name: '',
      date: '',
      place: '',
      city: '',
      address: '',
      price: 0,
      price_description: '',
    });
  };

  const launchConfetti = () => {
    function randomInRange(min, max) {
      return Math.random() * (max - min) + min;
    }

    if (confettiCanvas) {
      const confettiInstance = confetti.create(confettiCanvas, {
        resize: true,
        useWorker: true,
      });

      // Launch confetti in three different directions
      confettiInstance({
        angle: randomInRange(55, 70),
        spread: randomInRange(50, 70),
        particleCount: randomInRange(50, 100),
        origin: { y: 0.6 },
      });

      confettiInstance({
        angle: randomInRange(85, 95),
        spread: randomInRange(50, 70),
        particleCount: randomInRange(50, 100),
        origin: { y: 0.6 },
      });

      confettiInstance({
        angle: randomInRange(110, 125),
        spread: randomInRange(50, 70),
        particleCount: randomInRange(50, 100),
        origin: { y: 0.6 },
      });
    }
  };

  return (
    <div className="flex w-full flex-col gap-5 py-10 bg-gray-100 dark:bg-gray-900 min-h-screen">
      <div className="p-10 grid h-full grid-cols-1 gap-5 md:grid-cols-2 bg-white rounded-[20px] shadow-md dark:bg-grey-800 dark:text-white">
      <form onSubmit={handleSubmit} className="w-full">
          <h2 className="text-2xl font-semibold mb-4 text-center">Crea il tuo Evento</h2>
          <div className="mb-4">
            <label className="block mb-2">Nome Evento</label>
            <input
              type="text"
              name="name"
              value={eventData.name}
              onChange={handleChange}
              className="w-full p-3 border rounded bg-black-50 text-black-900"
              required
            />
          </div>
          <div className="mb-4">
            <label className="block mb-2">Data</label>
            <input
              type="date"
              name="date"
              value={eventData.date}
              onChange={handleChange}
              className="w-full p-3 border rounded bg-black-50 text-black-900"
              required
            />
          </div>
          <div className="mb-4">
            <label className="block mb-2">Luogo</label>
            <input
              type="text"
              name="place"
              value={eventData.place}
              onChange={handleChange}
              className="w-full p-3 border rounded bg-black-50 text-black-900"
              required
            />
          </div>
          <div className="mb-4">
            <label className="block mb-2">Città</label>
            <input
              type="text"
              name="city"
              value={eventData.city}
              onChange={handleChange}
              className="w-full p-3 border rounded bg-black-50 text-black-900"
              required
            />
          </div>
          <div className="mb-4">
            <label className="block mb-2">Indirizzo</label>
            <input
              type="text"
              name="address"
              value={eventData.address}
              onChange={handleChange}
              className="w-full p-3 border rounded bg-black-50 text-black-900"
              required
            />
          </div>
          <div className="mb-4 flex gap-4">
            <div className="w-1/2">
              <label className="block mb-2">Prezzo Ingresso</label>
              <input
                type="number"
                name="price"
                value={eventData.price}
                onChange={handleChange}
                className="w-full p-3 border rounded bg-black-50 text-black-900"
                required
              />
            </div>
            <div className="w-1/2">
              <label className="block mb-2">Descrizione Prezzo</label>
              <input
                type="text"
                name="price_description"
                value={eventData.price_description}
                onChange={handleChange}
                className="w-full p-3 border rounded bg-black-50 text-black-900"
                required
              />
            </div>
          </div>
          <button type="submit" className="w-full mt-4 bg-green-800 text-white py-3 rounded-lg">
            Crea Evento!
          </button>
        </form>

      </div>

      {/* Success Modal */}
      {showSuccessModal && (
        <div className="fixed inset-0 flex items-center justify-center w-full h-full bg-opacity-50 glassmorphism">
          <div className="bg-lightPrimary p-8 rounded-lg shadow-md text-center z-10">
            <h3 className="text-xl font-semibold mb-4">Fatto!</h3>
            <p>Il tuo evento è stato creato con successo!</p>
            <button
              onClick={handleSuccess}
              className="mt-4 bg-green-500 text-white py-2 px-10 rounded"
            >
              OK
            </button>
          </div>
        </div>
      )}

      {/* Error Modal */}
      {showErrorModal && (
        <div className="fixed inset-0 flex items-center justify-center w-full h-full bg-opacity-50 glassmorphism">
          <div className="bg-lightPrimary p-8 rounded-lg shadow-md text-center">
            <h3 className="text-xl font-semibold mb-4">Ops, Errore</h3>
            <p>Sembra che qualcosa sia andato storto mentre creavi il tuo evento.<br /> Riprova!</p>
            <button
              onClick={() => setShowErrorModal(false)}
              className="mt-4 bg-red-500 text-white py-2 px-10 rounded"
            >
              OK
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default CreateEvent;
