import axios from 'axios';
import React, { useState, useEffect } from 'react';
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import QRCodeTable from './components/QRCodeTable';
import { FiPlus } from "react-icons/fi";
import { Link } from 'react-router-dom';
import { FaRegCopy } from "react-icons/fa";
import { FaCheck } from "react-icons/fa6";


const Events = () => {
  const [events, setEvents] = useState([]);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [view, setView] = useState('events'); // 'events' or 'qrCodes'
  const [copySuccess, setCopySuccess] = useState(false);

  useEffect(() => {
    const showEvents = async () => {
      const header = sessionStorage.getItem('sessionId');
  
      try {
        const response = await axios.get('https://api.bashblitz.com/api/v2/events/getEventsByUser', {
          headers: {
            Authorization: header,
            'Content-Type': 'application/json' 
          }
        });
        if (response.status === 200) {
          const sortedEvents = response.data.events.sort((a, b) => new Date(b.details.date) - new Date(a.details.date));
          setEvents(sortedEvents);
          return;
        }
      } catch (error) {
        console.log('Error during API request:', error);
      }
    };

    showEvents();
  }, []);

  const handleEventClick = (event) => {
    setSelectedEvent(event);
    setView('details');
  };

  const handleQRCodeClick = () => {
    setView('qrCodes');
  };

  const handleBackClick = () => {
    setView('events');
    setSelectedEvent(null);
  };

  const formatDate = (dateString) => {
    const [year, month, day] = dateString.split('-');
    return `${day}-${month}-${year}`;
  };

  const isPastEvent = (dateString) => {
    const today = new Date();
    const eventDate = new Date(dateString);
    return eventDate < today;
  };

  const handleQRCodeCopy = () => {
    navigator.clipboard.writeText(selectedEvent.details.code_app).then(() => {
      setCopySuccess(true);
      setTimeout(() => {
        setCopySuccess(false);
      }, 3000)
    }, (err) => {
      setCopySuccess(false);
    });
  };

  return (
    <>

      <div className="grid h-full grid-cols-1 gap-5 xl:grid-cols-2 2xl:grid-cols-10 text-white mt-10">
        {view === 'events' && (
          <div className="col-span-1 xl:col-span-4 2xl:col-span-4">
            <h2 className="text-xl font-semibold mb-10 text-grey-900 dark:text-lightPrimary">Seleziona un Evento per maggiori dettagli</h2>
            <ul>
              {events.map(event => (
                <li
                  key={event.details.id}
                  className={`cursor-pointer p-5 mb-5 rounded-lg shadow-md transition duration-200 hover:cursor-pointer dark:hover:opacity-100 opacity-80 ${isPastEvent(event.details.date) ? 'bg-red-200 text-black-900 dark:bg-red-800 dark:text-white' : 'bg-white text-black-900 dark:bg-grey-600 dark:text-white'}`}
                  onClick={() => handleEventClick(event)}
                >
                <div className='flex items-center'>
                  <div className='flex flex-col'>
                    <b className='uppercase ms-2'>{event.details.name}</b>
                    <span className='ms-2'>{formatDate(event.details.date)}</span>
                  </div>
                  <IoIosArrowForward className='ms-auto'/>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        )}
        {view === 'details' && selectedEvent && (
          <div className="col-span-1 xl:col-span-8 2xl:col-span-7">
            <button onClick={handleBackClick} className="mb-5 dark:text-white text-grey-900 py-2 border-0">
              <div className='flex items-center'>
                <IoIosArrowBack className='me-2'/>Torna Indietro
              </div>
            </button>
            <div className='rounded-[20px] bg-white shadow-md dark:bg-grey-800 dark:text-white text-grey-800 p-10'>
              <h2 className="text-xl font-semibold mb-4 uppercase text-blue-500">Dettagli Evento:</h2>
              <table className="table-auto w-full md:w-1/2 text-left border-collapse">
                <tbody>
                  <tr>
                    <td className="pb-3 font-bold uppercase pr-4">Nome Evento:</td>
                    <td>{selectedEvent.details.name}</td>
                  </tr>
                  <tr>
                    <td className="pb-3 font-bold uppercase pr-4">Data:</td>
                    <td>{formatDate(selectedEvent.details.date)}</td>
                  </tr>
                  <tr>
                    <td className="pb-3 font-bold uppercase pr-4">Location:</td>
                    <td>{selectedEvent.details.place}</td>
                  </tr>
                  <tr>
                    <td className="pb-3 font-bold uppercase pr-4">Indirizzo:</td>
                    <td>{selectedEvent.details.address}</td>
                  </tr>
                  <tr>
                    <td className="pb-3 font-bold uppercase pr-4">Città:</td>
                    <td>{selectedEvent.details.city}</td>
                  </tr>
                  {selectedEvent.prices.map((price, index) => (
                    <tr>
                      <td className="pb-3 font-bold uppercase pr-4">{price.description}:</td>
                      <td>{price.price} €</td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <div className='flex flex-row'>
                <button onClick={handleQRCodeCopy} className="text-xl flex justify-center items-center w-full md:w-1/2 mt-4 bg-blue-500 shadow-md dark:text-white py-3 rounded">{!copySuccess ? <FaRegCopy className='me-3'/> : <FaCheck className='me-3'/> } {selectedEvent.details.code_app}</button>
              </div>
              <button onClick={handleQRCodeClick} className="w-full md:w-1/2 mt-4 bg-green-800 text-white py-3 rounded">GESTISCI QR-CODE</button>
            </div>
          </div>
        )}
        {view === 'qrCodes' && (
          <>
            <div className="col-span-1 xl:col-span-8  rounded-[20px] bg-white shadow-md dark:bg-grey-800 dark:text-white p-5 md:p-10">
              <button onClick={handleBackClick} className="mt-4 text-black-900 dark:text-white py-2 border-0">
                <div className='flex items-center'>
                  <IoIosArrowBack className='me-2'/>Torna Indietro
                </div>
              </button>
              <QRCodeTable idEvento={selectedEvent.details.id} eventName={selectedEvent.details.name} onBack={handleBackClick} />
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default Events;
