import React, { useEffect, useState } from 'react';
import TotalSpent from "views/admin/home/components/TotalSpent";
import { FaCoins } from "react-icons/fa";
import Widget from "components/widget/Widget";
import { GiPartyPopper } from "react-icons/gi";
import { GiMoneyStack } from "react-icons/gi";
import { FaCalendarDay } from "react-icons/fa";
import { FaUserFriends } from "react-icons/fa";
import { FaUsers } from "react-icons/fa";
import axios from 'axios';


const Dashboard = () => {

  const [chartData, setChartData] = useState([]);

  useEffect(() => {
    const showEvents = async () => {
      const header = sessionStorage.getItem('sessionId');
  
      try {
        const response = await axios.get('https://api.bashblitz.com/api/v2/dashboard/getDashboardData', {
          headers: {
            Authorization: header,
            'Content-Type': 'application/json' 
          }
        });
        if (response.status === 200) {
          setChartData(response.data.data);
          return;
        }
      } catch (error) {
        console.log('Error during API request:', error);
      }
    };

    showEvents();
  }, []);

  return (
    <div>
      {/* Card widget */}

      <div className="mt-10 grid grid-cols-1 gap-5 md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-3 3xl:grid-cols-6">
        <Widget
          icon={<FaCalendarDay className="h-7 w-7 p-1" />}
          title={"Eventi Attivi"}
          subtitle={chartData.num_active_events}
        />
        <Widget
          icon={<GiPartyPopper  className="h-7 w-7"/>}
          title={"Eventi Passati"}
          subtitle={chartData.total_organized_events - chartData.num_active_events}
        />
        <Widget
          icon={<FaUserFriends className="h-6 w-6" />}
          title={"Media Partecipanti"}
          subtitle={chartData.avg_scanned_tickets}
        />
        <Widget
            icon={<FaUsers className="h-6 w-6" />}
            title={"Totale Partecipanti"}
            subtitle={chartData.total_tickets}
        />
        <Widget
          icon={<FaCoins className="h-7 w-7 p-1" />}
          title={"Incasso Medio"}
          subtitle={`${chartData.total_earnings / chartData.total_organized_events}€`}
        />
        <Widget
          icon={<GiMoneyStack className="h-6 w-6"/>}
          title={"Totale Incasso"}
          subtitle={`${chartData.total_earnings}€`}
        />
      </div>

      {/* Charts */}

      <div className="mt-5 grid grid-cols-1">
        <TotalSpent data={chartData}/>
      </div>
    </div>
  );
};

export default Dashboard;
